export function fetchNotificationsSettingsPageData(){
    const {get:fetchNotification}=useOFetchCustom(`settings/generals/notifcations`)

    const notificationsData = useNotificationsSettignsData()

    async function fetch() {
        return new Promise((resolve, reject)=>{
            fetchNotification(undefined,{
                onSuccess:(response:any)=>{
                    notificationsData.value=response
                    resolve(resolve)
                },
                onError:(error:any)=>{
                    reject(error)
                }
            })
        })
    }
    return {fetch, notificationsData}

}

export default defineNuxtRouteMiddleware(async (to, from)=>{
    const {fetch} = fetchNotificationsSettingsPageData()

    try {
        await fetch()
    }
    catch(error:any){
        return navigateTo('/store')
    }
})